import React, { useContext, createContext, useState, useMemo, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import copy from 'copy-to-clipboard';
import getTimezone from '../utils/timezone';
import { asyncForEach } from '../utils/asyncHelpers';
import { DOMAINS } from '../domains';
import { IDomain, DomainStatusEnum, IDomainCheck } from '../models/Domain';
import { ILog, LogTypeEnum } from '../models/Log';

export interface ITestRunnerContext {
  requiredDomains: IDomain[];
  optionalDomains: IDomain[];
  logs: ILog[];
  testRunDate: string;
  isTestActive: boolean;
  runTest: () => void;
  copyLogs: () => void;
}

const TestRunnerContext = createContext<ITestRunnerContext | undefined>(undefined);

export const TestRunnerProvider = ({ children }: { children: React.ReactNode }) => {
  const [domains, setDomains] = useState<IDomain[]>(DOMAINS.sort((a, b) => a.order - b.order));
  const [logs, setLogs] = useState<ILog[]>([]);
  const [isTestActive, setIsTestActive] = useState<boolean>(false);

  const addToLogs = (description: string, type: LogTypeEnum = LogTypeEnum.Info) => {
    const currentDate = new Date();
    setLogs((logs) => [
      ...logs,
      {
        description,
        timestamp: `${currentDate.toLocaleTimeString()} ${getTimezone(currentDate)}`,
        type
      }
    ]);
  };

  const updateDomainStatus = (domain: IDomain, status: DomainStatusEnum) => {
    setDomains((domains) =>
      [
        ...domains.filter((item) => item.name !== domain.name),
        {
          order: domain.order,
          name: domain.name,
          checks: domain.checks,
          status,
          isRequired: domain.isRequired
        }
      ].sort((a, b) => a.order - b.order)
    );
  };

  const runTest = useCallback(() => {
    const run = async () => {
      setIsTestActive(true);
      setLogs([]);
      setDomains(
        domains
          .map((item) => ({
            order: item.order,
            name: item.name,
            checks: item.checks,
            status: DomainStatusEnum.Waiting,
            isRequired: item.isRequired
          }))
          .sort((a, b) => a.order - b.order)
      );

      await asyncForEach(domains, async (domain: IDomain) => {
        updateDomainStatus(domain, DomainStatusEnum.Checking);
        addToLogs(`Test connection to ${domain.name} started`);

        let httpFailed = false;
        await asyncForEach(domain.checks, async (check: IDomainCheck) => {
          const start = Date.now();

          try {
            await axios.request({
              url: `${check.url}?t=${new Date().getTime()}`,
              method: check.method,
              timeout: 7000
            });
            addToLogs(
              `Successfully connected to ${check.name} in ${Date.now() - start}ms`,
              LogTypeEnum.Success
            );
          } catch (err) {
            if ((err as AxiosError).response) {
              addToLogs(
                `Successfully connected to ${check.name} in ${Date.now() - start}ms`,
                LogTypeEnum.Success
              );
            } else {
              addToLogs(
                `Failed connection to ${check.name}. Timed out after ${Date.now() - start}ms`,
                LogTypeEnum.Fail
              );
              httpFailed = true;
            }
          }
        });

        if (httpFailed) {
          updateDomainStatus(domain, DomainStatusEnum.Failed);
        } else {
          updateDomainStatus(domain, DomainStatusEnum.Succeeded);
        }

        addToLogs(`Test ${domain.name} ended`);
      });

      setIsTestActive(false);
    };

    run();
  }, [domains]);

  const copyLogs = useCallback(() => {
    const logDescriptions = logs.map((log) => `${log.timestamp} ${log.description} \n`);
    copy(logDescriptions.join(''));
  }, [logs]);

  const contextValue = useMemo<ITestRunnerContext>(
    () => ({
      requiredDomains: domains.filter((item) => item.isRequired),
      optionalDomains: domains.filter((item) => !item.isRequired),
      logs,
      testRunDate: new Date().toDateString(),
      isTestActive,
      runTest,
      copyLogs
    }),
    [domains, logs, isTestActive, runTest, copyLogs]
  );

  return <TestRunnerContext.Provider value={contextValue}>{children}</TestRunnerContext.Provider>;
};

export const useTestRunner = () => {
  const context = useContext(TestRunnerContext);

  if (context === undefined) {
    throw new Error('useTestRunner must be used within an TestRunnerProvider');
  }

  return context;
};
