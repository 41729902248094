import React from 'react';
import { ReactComponent as Logo } from '../../images/logo.svg';
import styles from './Page.module.scss';

type PageContentProps = {
  left: React.ReactElement | React.ReactElement[];
  right: React.ReactElement | React.ReactElement[];
};

export const PageLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={styles.container} role="main">
    {children}
  </div>
);

export const PageHeader = () => (
  <header className={styles.header}>
    <Logo className={styles.headerLogo} />
    <h1 className={styles.headerText}>Network Test</h1>
  </header>
);

export const PageContent = ({ left, right }: PageContentProps) => (
  <div className={styles.content}>
    <div className={styles.leftColumn}>{left}</div>
    <div className={styles.rightColumn}>{right}</div>
  </div>
);
