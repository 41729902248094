import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTestRunner } from '../../context/testRunnerContext';
import { descriptionMap } from '../../utils/domainStatusHelpers';
import { IDomain, DomainStatusEnum } from '../../models/Domain';
import Card from '../card';
import styles from './DomainDetails.module.scss';

const DomainItem = ({ domain }: { domain: IDomain }) => {
  const renderIcon = (status: DomainStatusEnum) => {
    switch (status) {
      case DomainStatusEnum.Waiting:
        return <FontAwesomeIcon className={styles.icon} icon={faCircleNotch} />;
      case DomainStatusEnum.Checking:
        return <FontAwesomeIcon className={styles.icon} icon={faSpinner} spin />;
      case DomainStatusEnum.Succeeded:
        return <FontAwesomeIcon className={styles.icon} icon={faCheckCircle} />;
      case DomainStatusEnum.Failed:
        return <FontAwesomeIcon className={styles.icon} icon={faExclamationCircle} />;
      default:
        return null;
    }
  };

  return (
    <li
      className={classNames(styles.domainItem, {
        [styles.check]: domain.status === DomainStatusEnum.Checking,
        [styles.success]: domain.status === DomainStatusEnum.Succeeded,
        [styles.fail]: domain.status === DomainStatusEnum.Failed
      })}
    >
      <div className={styles.info}>
        <div className={styles.name}>{domain.name}</div>
        <div className={styles.status}>{descriptionMap[domain.status]}</div>
      </div>
      {renderIcon(domain.status)}
    </li>
  );
};

const DomainDetails = () => {
  const { requiredDomains, optionalDomains } = useTestRunner();

  return (
    <>
      <Card title="System Resources">
        <ul className={styles.domains}>
          {requiredDomains.map((item) => (
            <DomainItem key={item.name} domain={item} />
          ))}
        </ul>
      </Card>
      <Card title="Diagnostic Resources">
        <ul className={styles.domains}>
          {optionalDomains.map((item) => (
            <DomainItem key={item.name} domain={item} />
          ))}
        </ul>
      </Card>
    </>
  );
};

export default DomainDetails;
