import { IDomain, DomainStatusEnum } from './models/Domain';

export const DOMAINS: IDomain[] = [
  {
    order: 1,
    name: 'Commercial',
    checks: [
      { name: 'my.achieveit.com', url: 'https://my.achieveit.com/', method: 'GET' },
      { name: 'api.achieveit.com', url: 'https://api.achieveit.com/', method: 'GET' }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: true
  },
  {
    order: 2,
    name: 'US Government',
    checks: [
      { name: 'gov.achieveit.com', url: 'https://gov.achieveit.com/', method: 'GET' },
      { name: 'gov-api.achieveit.com', url: 'https://gov-api.achieveit.com/', method: 'GET' },
      {
        name: 'gov-sso.achieveit.com',
        url: 'https://gov-sso.achieveit.com/.well-known/openid-configuration',
        method: 'GET'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: true
  },
  {
    order: 3,
    name: 'auth0.com',
    checks: [
      {
        name: 'achieve-it.auth0.com',
        url: 'https://achieve-it.auth0.com/.well-known/openid-configuration',
        method: 'GET'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: true
  },
  {
    order: 4,
    name: 'achieveit.com',
    checks: [
      {
        name: 'www.achieveit.com',
        url: 'https://www.achieveit.com/wp-json',
        method: 'GET'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: false
  },
  {
    order: 5,
    name: 'google-analytics.com',
    checks: [
      {
        name: 'www.google-analytics.com',
        url: 'https://www.google-analytics.com/collect',
        method: 'GET'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: false
  },
  {
    order: 6,
    name: 'dc.services.visualstudio.com',
    checks: [
      {
        name: 'dc.services.visualstudio.com',
        url: 'https://dc.services.visualstudio.com/v2/track',
        method: 'POST'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: false
  },
  {
    order: 8,
    name: 'pendo.io',
    checks: [
      { name: 'app.pendo.io', url: 'https://app.pendo.io/data/guide.json', method: 'GET' },
      {
        name: 'cdn.pendo.io',
        url: 'https://cdn.pendo.io/agent/static/5a6888bb-f93c-4518-5d45-0e9a78188a55/pendo.js',
        method: 'GET'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: false
  },
  {
    order: 9,
    name: 'zendesk.com',
    checks: [
      {
        name: 'ekr.zdassets.com',
        url: 'https://ekr.zdassets.com/compose/web_widget/achieveitonline.zendesk.com',
        method: 'GET'
      },
      {
        name: 'achieveitonline.zendesk.com',
        url: 'https://achieveitonline.zendesk.com/embeddable/config',
        method: 'GET'
      }
    ],
    status: DomainStatusEnum.Waiting,
    isRequired: false
  }
];
