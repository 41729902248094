import { useState, useRef } from 'react';
import classNames from 'classnames';
import { useTestRunner } from '../../context/testRunnerContext';
import Card from '../card';
import { ILog, LogTypeEnum } from '../../models/Log';
import styles from './LogDetails.module.scss';

const LogItem = ({ log }: { log: ILog }) => (
  <li className={styles.logItem}>
    <div className={styles.timestamp}>{log.timestamp}</div>
    <div
      className={classNames(styles.description, {
        [styles.success]: log.type === LogTypeEnum.Success,
        [styles.fail]: log.type === LogTypeEnum.Fail
      })}
    >
      {log.description}
    </div>
  </li>
);

const LogDetails = () => {
  const { testRunDate, isTestActive, logs, runTest, copyLogs } = useTestRunner();
  const [copyText, setCopyText] = useState<string>('Copy Log');
  const timeoutRef = useRef<NodeJS.Timeout>();
  const title: string = `Log for test run ${testRunDate}`;

  const handleCopy = () => {
    copyLogs();
    setCopyText('Copied');

    const timeout = timeoutRef.current;
    if (timeout) {
      timeoutRef.current = undefined;
      clearTimeout(timeout);
    }

    timeoutRef.current = setTimeout(() => {
      setCopyText('Copy Log');
    }, 3000);
  };

  return (
    <Card title={title} fullContent>
      <ul className={styles.logs}>
        {logs.map((item, index) => (
          <LogItem key={index} log={item} />
        ))}
      </ul>
      <div className={styles.details}>
        <h2 className={styles.header}>Failed Connection?</h2>
        <ol className={styles.info}>
          <li>Rerun the test connection</li>
          <li>If rerunning fails, copy the log</li>
          <li>
            Email the log to your Customer Success Manager or{' '}
            <a href="mailto:support@achieveit.com">support@achieveit.com</a>
          </li>
        </ol>
        <div className={styles.buttonContainer}>
          <button className={styles.button} type="button" disabled={isTestActive} onClick={runTest}>
            <span className={styles.buttonText}>Rerun Test</span>
          </button>
          <button
            className={classNames(styles.button, styles.primary)}
            type="button"
            disabled={isTestActive}
            onClick={handleCopy}
          >
            <span className={styles.buttonText}>{copyText}</span>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default LogDetails;
