import React from 'react';
import classNames from 'classnames';
import styles from './Card.module.scss';

type CardProps = {
  title: string;
  fullContent?: boolean;
  children: React.ReactNode;
};

const Card = ({ title, fullContent = false, children }: CardProps) => (
  <section className={classNames(styles.card, { [styles.fullContent]: fullContent })}>
    <h3 className={styles.cardHeader}>{title}</h3>
    <div>{children}</div>
  </section>
);

export default Card;
