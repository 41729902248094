import { useEffect } from 'react';
import { PageLayout, PageHeader, PageContent } from './components/page';
import DomainDetails from './components/domain-details/DomainDetails';
import LogDetails from './components/log-details';
import { useTestRunner } from './context/testRunnerContext';

const App = () => {
  const { runTest } = useTestRunner();

  useEffect(() => {
    runTest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout>
      <PageHeader />
      <PageContent left={<DomainDetails />} right={<LogDetails />} />
    </PageLayout>
  );
};

export default App;
