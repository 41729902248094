export interface IDomain {
  order: number;
  name: string;
  checks: IDomainCheck[];
  status: DomainStatusEnum;
  isRequired: boolean;
}

export enum DomainStatusEnum {
  Waiting,
  Checking,
  Succeeded,
  Failed
}

export interface IDomainCheck {
  name: string;
  url: string;
  method: 'GET' | 'POST' | 'OPTIONS';
}
