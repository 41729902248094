const getTimezone = (date: Date) => {
  const time = date.toTimeString();
  const parts = time.match(/\(([^)]+)\)/i);
  let timezone = parts![1];

  if (timezone.search(/\W/) >= 0) {
    const matches = timezone.match(/\b\w/g);
    if (matches) {
      timezone = matches.join('').toUpperCase();
    } else {
      timezone = '';
    }
  }

  return timezone;
};

export default getTimezone;
