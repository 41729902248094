export interface ILog {
  description: string;
  timestamp: string;
  type: LogTypeEnum;
}

export enum LogTypeEnum {
  Info,
  Success,
  Fail
}
